import {
  exchangePointsShopReward,
  fetchCustomerLoyalty,
  fetchCustomerRewards,
  fetchPointsShop,
  selectCustomerLoyalty,
  selectPointsShop,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { makeDiscountEnhanced } from '@open-tender/utils'
import { useEffect } from 'react'

const usePointsShop = () => {
  const dispatch = useAppDispatch()

  const { entities, loading, error } = useAppSelector(selectPointsShop)
  const customerLoyalty = useAppSelector(selectCustomerLoyalty)

  const points = customerLoyalty.entities.find(
    (e) => e.loyalty_type === 'POINTS'
  )?.perk?.credit

  const pointsBalance = points ? parseFloat(points) : null

  const rewards = entities.map((r) => {
    const p = makeDiscountEnhanced(r)
    return {
      id: p.discount_id.toString(),
      description: p.description,
      image: p.imageUrl!,
      is_available: true,
      name: p.name,
      points: r.points_shop_cost ? parseFloat(r.points_shop_cost) : 0,
      expires_at: p.expiration ?? '',
    }
  })

  const hasPointsRewards = rewards.length > 0
  const isLoading = loading === 'pending'
  const updatingBalance = customerLoyalty.loading === 'pending'

  useEffect(() => {
    dispatch(fetchPointsShop())
    dispatch(fetchCustomerLoyalty())
  }, [dispatch])

  const handleExchangePoints = async (id: string) => {
    await dispatch(exchangePointsShopReward(id))
    dispatch(fetchCustomerLoyalty())
    dispatch(fetchCustomerRewards())
  }

  return {
    rewards,
    pointsBalance,
    hasPointsRewards,
    isLoading,
    updatingBalance,
    error,
    handleExchangePoints,
  }
}

export default usePointsShop
